@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimProRegular.woff2') format('woff2'),
  url('../fonts/GTWalsheimProRegular.woff') format('woff'),
  url('../fonts/GTWalsheimProRegular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimProLight.woff2') format('woff2'),
  url('../fonts/GTWalsheimProLight.woff') format('woff'),
  url('../fonts/GTWalsheimProLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimProLight-Oblique.woff2') format('woff2'),
  url('../fonts/GTWalsheimProLight-Oblique.woff') format('woff'),
  url('../fonts/GTWalsheimProLight-Oblique.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimProMedium.woff2') format('woff2'),
  url('../fonts/GTWalsheimProMedium.woff') format('woff'),
  url('../fonts/GTWalsheimProMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimProMedium-Oblique.woff2') format('woff2'),
  url('../fonts/GTWalsheimProMedium-Oblique.woff') format('woff'),
  url('../fonts/GTWalsheimProMedium-Oblique.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimProBold.woff2') format('woff2'),
  url('../fonts/GTWalsheimProBold.woff') format('woff'),
  url('../fonts/GTWalsheimProBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../fonts/GTWalsheimProBold-Oblique.woff2') format('woff2'),
  url('../fonts/GTWalsheimProBold-Oblique.woff') format('woff'),
  url('../fonts/GTWalsheimProBold-Oblique.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@layer base {
  :root {
    --font-primary: "GT Walsheim Pro", sans-serif;
    --font-secondary: "GT Walsheim Pro", sans-serif;

    --background: 255 255 255;
    --foreground: 0 0 0;
    --foreground-secondary: 132 132 132;

    --primary: 79 45 127;
    --primary-foreground: 255 255 255;
    --secondary: 233 40 66;

    --ternary: 0 166 181;

    --border: 230 229 229;
    --input: 132 132 143;

    --radius: 0;
  }

  body {
    @apply font-primary bg-background text-foreground;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply font-secondary font-medium;
  }

  h1 {
    @apply text-5xl leading-[50px] md:text-[66px] lg:text-[64px] text-primary-foreground md:leading-[70px] lg:leading-[88px];
  }

  h2 {
    @apply text-3xl lg:leading-[80px];
  }
}
